import { addChildRoute, addRoutes, addRoute } from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import { getCatalogRoutes } from "@pazion/pazion-catalog/src/module-config";
import OrderListWrapper from "./views/OrderListWrapper.vue";

addRoutes(getCatalogRoutes());

addRoute({
    path: "/orders",
    name: "orders",
    component: OrderListWrapper,
    meta: {
        "show-in-nav": true,
        profilePermission: "orderManagement.show",
        roles: ["user"],
        sort: -70,
    },
});

/*
 customize...
redirectRouteToBackend('invoices', true);
removeRouteByName('invoices');
redirectRouteToBackend('products', true);
addRoute(  {
  path: '/invoices',
  name: 'invoices',
  component: AppOrderList,
  meta: {
    'show-in-nav': true,
    roles: ['user'],
  },
  });
*/
