
import { Component, Vue } from "vue-property-decorator";
import OrderListDisplayWrapper from "@pazion/pazion-catalog/src/views/orders/OrderListDisplayWrapper.vue";

@Component({
  components: {
    OrderListDisplayWrapper,
  },
})
export default class OrderListWrapper extends Vue {}
